import { ModalContent } from '@/interfaces';

export const useRecipient = () => {
  const { t } = useI18n();

  const getRecipient = (): Array<ModalContent> => [
    {
      id: 'recipient-create',
      title: t('components.common.recipient.addingRecipient'),
      description: t('components.common.recipient.enterPlantation'),
      action: 'create',
      request: '/recipient',
      method: 'post',
      submitName: t('components.common.add'),
      emit: 'plantation-action',
    },
  ];

  return {
    recipient: getRecipient(),
  }
}
