import {
  RecipientCreateTypeList,
  RecipientCreateTypeName,
  StructureInput,
  StructureModule,
} from '@/interfaces';
import { objectCollection } from '..';
import { apiPlantation } from '@/services';

export const useInitialInputsRecipient = (data: Record<string, any>) => {
  const { t } = useI18n();

  const inputs = (): Array<StructureInput> => [
    {
      id: 'type',
      name: t('components.common.inputs.recipient'),
      placeholder: t('components.common.inputs.selectRecipient'),
      grid: '1 / 13',
      type: 'drop-single',
      drop: RecipientCreateTypeList,
      drop_enum: RecipientCreateTypeName,
      drop_context: RecipientCreateTypeList,
      show: true,
      search: false,
      required: true,
    },
    {
      id: 'plantation',
      name: t('components.common.inputs.plantation'),
      placeholder: t('components.common.inputs.selectPlantation'),
      grid: '1 / 13',
      type: 'drop-single',
      drop: [],
      drop_enum: [],
      search: true,
      show: true,
      required: true,
    },
    {
      id: 'plantationName',
      name: t('components.common.inputs.plantation'),
      placeholder: t('components.common.inputs.enterPlantationName'),
      grid: '1 / 13',
      type: 'text',
      show: false,
      required: false,
    },
    {
      id: 'number',
      name: t('components.common.inputs.accounts'),
      placeholder: t('components.common.inputs.enterAccountNumber'),
      grid: '1 / 13',
      type: 'text',
      show: true,
      required: true,
    },
    {
      id: 'city',
      name: t('components.common.inputs.city'),
      placeholder: t('components.common.inputs.enterCity'),
      grid: '1 / 13',
      type: 'text',
      // drop: [],
      // drop_context: [],
      // drop_enum: {},
      // drop_request: apiPlantation.getCityList.bind(apiPlantation),
      // search: true,
      show: true,
      required: true,
    },
    {
      id: 'zip',
      name: t('components.common.inputs.postalCode'),
      placeholder: t('components.common.inputs.enterPostcode'),
      grid: '1 / 13',
      type: 'text',
      mask: '*'.repeat(6),
      show: true,
      required: true,
    },
    {
      id: 'country',
      name: t('components.common.inputs.bankCountry'),
      placeholder: t('components.common.inputs.selectBankCountry'),
      grid: '1 / 13',
      type: 'drop-single',
      drop: [],
      drop_context: [],
      drop_enum: {},
      search: true,
      show: true,
      required: true,
    },
    {
      id: 'routingNumber',
      name: 'Routing Number',
      placeholder: t('components.common.inputs.enterRoutingNumber'),
      grid: '1 / 13',
      type: 'text',
      mask: '#'.repeat(18),
      show: false,
      required: false,
    },
    {
      id: 'swift',
      name: t('components.common.inputs.SWIFTBankCode'),
      placeholder: t('components.common.inputs.enterCode'),
      grid: '1 / 13',
      type: 'text',
      mask: '*'.repeat(11),
      show: false,
      required: false,
    },
  ];

  const getRecipient = (data: Record<string, any>): Array<StructureModule> => {
    return [
      {
        id: 'recipient-create',
        data: objectCollection(data, ['type']),
        inputs: inputs(),
        classList: 'text-sm',
      },
    ];
  };

  return {
    recipient: getRecipient(data),
  }
}
