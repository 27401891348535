import { ModalInitial, ModalContent } from "@/interfaces";

import { useOrder } from "./order";
import { useBalance } from "./balance";
import { useRecipient } from "./recipient";

export const initialContent = ({ id, data }: ModalInitial): ModalContent => {
  const { order } = useOrder()
  const { balance } = useBalance()
  const { recipient } = useRecipient()

  const contents = [
    ...order,
    ...balance,
    ...recipient,
  ] as ModalContent[];
  return contents.find((content) => content.id === id) || contents[0];
};
