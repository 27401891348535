import { ModalContent } from '@/interfaces';

export const useOrder = () => {
  const { t } = useI18n();

  const getOrder = (): Array<ModalContent> => [
    {
      id: 'order-create',
      title: t('components.common.order.reqForTrans'),
      description: t('components.common.order.enterAmount'),
      action: 'create',
      request: '/order',
      method: 'post',
      submitName: t('components.common.order.pay'),
      emit: 'account-action',
    },
    {
      id: 'order-confirmed',
      title: t('components.common.order.confirmOfReceipt'),
      action: 'edit',
      request: '/order/confirmed',
      method: 'patch',
      message: t('components.common.order.plantationReceivedFunds'),
      cancelName: t('components.common.no'),
      submitName: t('components.common.yes'),
      emit: 'table-action',
    },
  ];

  return {
    order: getOrder(),
  }
}
