import { ModalInitial, StructureModule } from '@/interfaces';

import { useInitialInputsOrder } from './order';
import { useInitialInputsBalance } from './balance';
import { useInitialInputsRecipient } from './recipient';

const untouchableVariables = ['cover'];

export const objectCollection = (data: Record<string, any>, properties: Array<string>) => {
  return properties.reduce((acc: Record<string, any>, property: string) => {
    if (
      data[property] &&
      Object.hasOwn(data[property], '_id') &&
      !untouchableVariables.includes(property)
    ) {
      acc[property] = data[property]._id;
    } else {
      const falsy = data[property] != null ? data[property] : '';
      acc[property] = falsy;
    }
    return acc;
  }, {});
};

export const initialInputs = ({ id, data }: ModalInitial): StructureModule | null => {

  // @ts-ignore
  const inputs = [
    { id: 'order', inputs: (data) => useInitialInputsOrder(data).order },
    { id: 'balance', inputs: (data) => useInitialInputsBalance(data).balance },
    { id: 'recipient', inputs: (data) => useInitialInputsRecipient(data).recipient },
  ] as {
    id: string;
    inputs: (data: any) => StructureModule[];
  }[];

  const current = inputs.find((input) => id.includes(input.id));
  if (!current) return null;

  return current.inputs(data).find((input) => input.id === id) || null;
};
