import { OrderStatus, StructureInput, StructureModule } from '@/interfaces';
import { objectCollection } from '..';

export const useInitialInputsOrder = (data: Record<string, any>) => {
  const { t } = useI18n();

  const inputs = (): Array<StructureInput> => [
    {
      id: 'name',
      name: t('components.common.inputs.recipient'),
      placeholder: '',
      grid: '1 / 13',
      type: 'text',
      show: true,
      required: true,
      disabled: true,
    },
    {
      id: 'recipients',
      name: '',
      placeholder: '',
      grid: '1 / 13',
      type: 'recipients',
      show: true,
      required: true,
    },
    {
      id: 'accounts',
      name: t('components.common.inputs.debitFromAccount'),
      placeholder: '',
      grid: '1 / 13',
      type: 'accounts',
      show: true,
      required: true,
    },
    {
      id: 'amount',
      name: t('components.common.inputs.amount'),
      placeholder: t('components.common.inputs.enterAmount'),
      grid: '1 / 13',
      type: 'text',
      show: true,
      required: true,
    },
  ];

  const getOrder = (data: Record<string, any>): Array<StructureModule> => {
    return [
      {
        id: 'order-create',
        data: objectCollection(data, ['plantation', 'name', 'recipients']),
        inputs: inputs(),
        classList: 'text-sm',
      },
      {
        id: 'order-confirmed',
        data: objectCollection({ ...data, status: OrderStatus.CONFIRMED }, ['_id', 'status']),
        inputs: [],
        classList: 'text-sm',
      },
    ];
  };

  return {
    order: getOrder(data),
  }
}
