import { ModalContent } from '@/interfaces';

export const useBalance = () => {
  const { t } = useI18n();

  const getBalance = ():Array<ModalContent> => ([
    {
      id: 'balance-qrcode',
      title: t('components.common.balance.title'),
      description: t('components.common.balance.description'),
      action: 'create',
      request: '/transaction/qrcode',
      fileRequest: '/file/transaction',
      method: 'post',
      fileMethod: 'patch',
      submitName: t('components.common.balance.submitName'),
      emit: 'transaction-action',
      fileEmit: 'transaction-action',
      rejectInput: t('components.common.balance.rejectInput'),
    },
    {
      id: 'balance-cash',
      title: t('components.common.balance.title'),
      description: t('components.common.balance.enterAmount'),
      action: 'create',
      request: '/transaction/cash',
      method: 'post',
      submitName: t('components.common.balance.submitName'),
      emit: 'transaction-action',
    },
    {
      id: 'balance-crypto',
      title: t('components.common.balance.title'),
      description: t('components.common.balance.enterAmount'),
      action: 'create',
      request: '/transaction/crypto',
      method: 'post',
      submitName: t('components.common.balance.submitName'),
      emit: 'wallet-action',
    },
  ])

  return {
    balance: getBalance(),
  }
}

