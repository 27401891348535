<script setup lang="ts">
  import { StructureModule } from '@/interfaces';
  import { ModalContent } from '@/interfaces';

  const { t } = useI18n();

  const props = defineProps<{
    message: ModalContent['message'];
    action: ModalContent['action'];
    data: StructureModule['data'];
  }>();

  const isDeleteAction = props.action === 'delete';
</script>

<template>
  <div class="message">
    <div v-if="isDeleteAction">
      <span>{{ t('components.common.youReallyWant') }} {{ message }}</span>
      <span v-if="data.name" class="fw500"> "{{ data.name }}"</span>
      <span>?</span>
    </div>
    <div v-else>
      {{ message }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.message {
  div {
    line-height: 130%;
    white-space: pre-line;
  }
}
</style>
