<script setup lang="ts">
import { ModalContent } from '@/interfaces';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'submitHandler'): void;
  (e: 'cancelHandler'): void;
}>();

defineProps<{
  submitName: ModalContent['submitName'];
  cancelName: ModalContent['cancelName'];
  pending: boolean;
}>();
</script>

<template>
  <div class="modal-actions flex flex-je text-sm">
    <common-button
      v-if="cancelName"
      :text="cancelName"
      class-mods="btn-accent btn-fit"
      @click="emit('cancelHandler')"
    ></common-button>
    <common-button
      v-if="!pending"
      :text="submitName || ''"
      class-mods="btn-accent btn-fit"
      @click="emit('submitHandler')"
    ></common-button>
    <common-button v-else class-mods="btn-accent btn-fit" :text="t('components.common.processing')"></common-button>
  </div>
</template>

<style scoped lang="scss">
.modal-actions {
  @apply gap-3;
}
</style>
